import React from "react"
import { MDXProvider, MDXProviderComponents } from "@mdx-js/react"
import { GatsbyBrowser } from "gatsby"
import Code from "./code"

const components: MDXProviderComponents = {
  pre: (props) => <Code {...props} />,
}

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  return <MDXProvider components={components}>{element}</MDXProvider>
}
